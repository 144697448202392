import React, { Fragment, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  Divider,
  Box,
  Link as StyleLink,
  Fab,
  Grid,
  Button,
} from "@material-ui/core"
import { PlayArrow as PlayIcon } from "@material-ui/icons"

import SEO from "../components/seo"

const useStyles = makeStyles({
  button: {
    zIndex: 1,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  image: {
    transition: "transform .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
})

const About = () => {
  const classes = useStyles()

  useEffect(() => {
    console.log("=== About ===")
  })

  const { about } = useStaticQuery(graphql`
    query {
      about: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <SEO title="A propos du projet" slug="/about" />

      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={12} md={6} lg={8}>
          <Typography component={Box} align="justify">
            <Typography paragraph variant="h4">
              Le projet
            </Typography>

            <Typography paragraph>
              RandoPassion est une collaboration de vidéastes francophones
              (France, Suisse, Belgique, Québec) passionnés de randonnée.
              RandoPassion rassemble des témoignages de randonneuses et de
              randonneurs aux profils, pratiques et expériences variés.
            </Typography>
            <Typography paragraph>
              À travers ces portraits, nous souhaitons partager nos motivations
              et croiser nos regards sur la randonnée : pourquoi avons-nous
              choisi cette pratique ? Qu'est-ce qu’elle nous procure ?
            </Typography>
            <Typography paragraph>
              Nous aimerions susciter la curiosité, faire découvrir de nouvelles
              façons d’approcher la randonnée et nourrir des réflexions autour
              de notre passion.
            </Typography>
            <Typography paragraph>Bon visionnage !</Typography>

            <Button
              color="primary"
              variant="outlined"
              href="https://www.youtube.com/watch?v=3q-w-k4-mZc"
              target="_blank"
              rel="noopener noreferrer"
            >
              voir la vidéo
            </Button>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box position="relative">
            <Box position="absolute" top={0} right={0} bottom={0} left={0}>
              <Box position="relative" paddingTop="calc(9 / 16 * 100%)">
                <Box
                  position="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <StyleLink
                    href="https://www.youtube.com/watch?v=3q-w-k4-mZc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Fab size="large" className={classes.button}>
                      <PlayIcon fontSize="large" />
                    </Fab>
                  </StyleLink>
                </Box>
              </Box>
            </Box>

            <Box
              position="relative"
              paddingTop="calc(9 / 16 * 100%)"
              overflow="hidden"
            >
              <StyleLink
                href="https://www.youtube.com/watch?v=3q-w-k4-mZc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  position="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  className={classes.image}
                >
                  <Img fluid={about.childImageSharp.fluid} />
                </Box>
              </StyleLink>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box>
        <Box marginY={4}>
          <Divider />
        </Box>

        <Typography paragraph variant="h4">
          Contact
        </Typography>

        <Typography paragraph align="justify">
          Si vous souhaitez participer, mais que vous n’avez pas de plateforme
          vidéo ou pour toutes autres questions concernant le projet vous pouvez
          contacter Adrien (PleinAir Enthousiaste) :
        </Typography>

        <Typography paragraph component={Box}>
          <ul>
            <li>
              Par email :{" "}
              <StyleLink
                href="mailto:pleinair.enthousiaste@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                pleinair.enthousiaste@gmail.com
              </StyleLink>
            </li>
            <li>
              Via Instagram :{" "}
              <StyleLink
                href="https://www.instagram.com/pleinair_enthousiaste/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.instagram.com/pleinair_enthousiaste
              </StyleLink>
            </li>
          </ul>
        </Typography>
      </Box>
    </Fragment>
  )
}

export default About
